<template>
  <dl-dialog
    title="切换绑定手机号"
    width="500px"
    :append-to-body="true"
    :visibleNum="visibleNum"
    :confirmPromise="confirmPromise"
  >
    <dl-form
      ref='dataForm'
      :key="visibleNum"
      :formAttributeArr="formAttributeArr"
    ></dl-form>
  </dl-dialog>
</template>

<script>
import { CaptchaCode, SendPhoneCode } from '@/components'
import { request } from '@/utils'
export default {
  components: {
    CaptchaCode,
  },
  props: {
    visibleNum: Number,
  },
  data () {
    return {
      formAttributeArr: [
        {
          type: 'input',
          keyName: 'password',
          formItem: {
            label: '当前密码',
            rules: {required: true}
          },
          compAttrs: {
            showPassword: true,
          }
        },
        {
          type: 'input',
          keyName: 'phone',
          formItem: {
            label: '新手机号',
            rules: {required: true}
          },
        },
        {
          type: 'input',
          keyName: 'captcha_code',
          formItem: {
            label: '验证码',
            rules: {required: true}
          },
          component: CaptchaCode
        },
        {
          type: 'input',
          keyName: 'verify_code',
          formItem: {
            label: '短信验证',
            rules: {required: true}
          },
          compAttrs: {
            ensure_user_exists: true,
          },
          component: SendPhoneCode
        },
      ]
    }
  },
  computed: {},
  methods: {
    confirmPromise(data) {
      return request({
        method: 'PATCH',
        url: '/api/users/phone',
        data: {
          ...data,
          captcha_code: null,
        }
      }).then((res) => {
        this.$emit('change-phone', res.data.phone)
      })
    }
  }
}
</script>
<style lang='scss' scoped>
</style>