<template>
  <div class="user-detail-comp">
    <UserImg
      :key="imgKey"
      width='120px'
      height='120px'
    />
    <el-upload
      class="upload-demo"
      :http-request="httpUpload"
      :limit="1"
      action=""
      :show-file-list="false"
    >
      <el-button
        plain
        class="btn"
        size="small"
        type="primary"
      >修改头像</el-button>
      <!-- <div
        slot="tip"
        class="el-upload__tip"
      >只能上传jpg/png文件，且不超过500kb</div> -->
    </el-upload>
  </div>
</template>

<script>
import { request } from '@/utils'
import { UserImg } from '@/components'
export default {
  components: {
    UserImg
  },
  props: {
  },
  data () {
    return {
      imgKey: 0
    }
  },
  computed: {},
  methods: {
    handleChange(file) {
      this.file = file
    },
    httpUpload(params) {
      const formData = new FormData()
      formData.append('image', params.file)
      request({
        url: '/api/users/upload_image',
        method: 'post',
        data: formData,
      }).then(res => {
        this.$message.success('头像修改成功')
        this.$store.dispatch('ChangeImageUrl', res.data)
      })
    },
  }
}
</script>
<style lang='scss' scoped>
.user-detail-comp {
  display: flex;
  align-items: center;
  .btn {
    margin-left: 10px;
  }
}
</style>