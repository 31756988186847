<!--
 * @Description: 个人信息页面
-->
<template>
  <div class="person-page">
    <HeadTitle text="个人信息"></HeadTitle>
    <div class="content g-ct-cn">
      <UserImgChange></UserImgChange>
      <div class="u-regular score-div">积分： {{userInfo.score}}</div>
      <el-divider></el-divider>
      <dl-form
        ref='refForm'
        class="user-form"
        :key="formKey"
        :formAttributeArr="formAttributeArr"
        :defaultFormData="userInfo"
        :disabled="formDisabled"
      >
        <template v-slot:formItem>
          <div>
            <el-button
              v-if="formDisabled"
              type="primary"
              @click='toEdit'
            >编辑</el-button>
            <div v-else>
              <el-button @click='cancelEdit'>取消</el-button>
              <el-button
                type="primary"
                @click='updateInfo'
              >更新</el-button>
            </div>
          </div>
        </template>
      </dl-form>
    </div>
  </div>
</template>

<script>
import { HeadTitle } from '@/components'
import { UserImgChange, ChangePhone, ChangeEmail } from './components'
import { request } from '@/utils'
export default {
  components: {
    HeadTitle,
    ChangePhone,
    ChangeEmail,
    UserImgChange
  },
  props: {
  },
  data () {
    return {
      formKey: 0,
      formAttributeArr: [
        {
          type: 'input',
          keyName: 'nickname',
          formItem: {
            label: '用户名',
            rules: {required: true}
          },
        },
        {
          type: 'radio',
          keyName: 'sex',
          formItem: {
            label: '性别',
            rules: {required: false}
          },
          compAttrs: {
            options: [
              {
                label: '男',
                value: 0,
              },
              {
                label: '女',
                value: 1,
              }
            ],
          }
        },
        {
          keyName: 'phone',
          formItem: {
            label: '手机号',
            rules: {required: true}
          },
          component: ChangePhone,
        },
        {
          type: 'input',
          keyName: 'email',
          formItem: {
            label: '邮箱',
            rules: {required: false}
          },
          component: ChangeEmail,
        },
        {
          type: 'input',
          keyName: 'qq',
          formItem: {
            label: 'QQ',
          }
        },
        {
          type: 'input',
          keyName: 'wx',
          formItem: {
            label: '微信',
          }
        },
        {
          type: 'input',
          keyName: 'company',
          formItem: {
            label: '单位',
          }
        },
        {
          type: 'input',
          keyName: 'position',
          formItem: {
            label: '职位',
          }
        },
        {
          type: 'textarea',
          keyName: 'signature',
          formItem: {
            label: '个性签名',
            rules: {required: false}
          }
        },
      ],
      formDisabled: true,
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.info
    }
  },
  methods: {
    toEdit() {
      this.formDisabled = false
    },
    cancelEdit() {
      this.formDisabled = true
      this.formKey++
    },
    updateInfo() {
      const { data } = this.$refs.refForm.validate()
      if (data) {
        request({
          method: 'PATCH',
          url: '/api/users/state',
          data
        }).then((res) => {
          this.$store.dispatch('GetInfo')
          this.$message.success('更新个人信息成功')
          this.formDisabled = true
        })
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.person-page {
  .score-div {
    width: 120px;
    text-align: center;
    margin-top: 10px;
  }
  .content {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .user-form {
    width: 400px;
  }
}
</style>