<!--
 * @Description:
 * @Date: 2021-05-26 17:26:59
-->
<template>
  <div class="phone-comp">
    <el-input
      :disabled="true"
      class="input-wrap"
      :value="inputValue"
      :placeholder="data.placeholder"
    ></el-input>
    <el-button @click="visibleNum++">设置</el-button>
    <ChangeDialog
      @change-email="changeEmail"
      :visibleNum="visibleNum"
    />
  </div>
</template>

<script>
import ChangeDialog from './dialog.vue'
export default {
  components: {
    ChangeDialog
  },
  props: {
    data: {
      type: Object,
      require: false
    },
    defaultValue: String,
    getFormData: Function
  },
  data () {
    return {
      visibleNum: 0,
      inputValue: this.defaultValue
    }
  },
  mounted() {
  },
  methods: {
    changeEmail(value) {
      this.inputValue = value
      this.updateValueToForm()
    },
    // data对应的就是formData里面的数据，所以需要出发updateValue
    updateValueToForm() {
      if (this.data) {
        this.$emit('updateValue', this.data.keyName, this.inputValue, 'custom')
      }
    }
  },
}
</script>
<style lang='scss' scoped>
.phone-comp {
  white-space: nowrap;
  .input-wrap {
    width: 100%;
    margin-right: 10px;
  }
}
</style>
